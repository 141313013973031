import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAxios from "../../Hooks/useAxios";
import "./OldOrder.css";
const OldOrder = () => {
  const axios = useAxios();
  const [data, setData] = useState([]);
  const [call, setCall] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get("/order/single-user-order")
      .then((res) => {
        setData(res?.data?.data);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [call]);

  const cancelOrder = (id) => {
    setLoading(true);
    axios
      .put(`/order/cancel-order/${id}`)
      .then((res) => {
        setCall(!call);
        setLoading(false);
      })
      .catch((err) => {
        setCall(!call);
        setLoading(false);
      });
  }

  const isCancelabel = (order) => {

    if (order?.status !== "Pending") {
      return false;
    }
    const date1 = new Date();
    const date2 = new Date(order?.createdAt);
    const diffTime = Math.abs(date1 - date2);
    if (diffTime > 300000) {
      return false
    }
    else {
      return true
    }

  }
  return (
    <div className="user_profile">
      <div className="container">
        <div className="row my-5">
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="inner_my_account_left">
              <div className="personal_info">
                <div className="account">
                  <p className="border-bottom pb-1">My Account</p>
                </div>
                <div className="user_info ">
                  <Link to="/user_profile">
                    <i className="far fa-user me-3"></i>
                    <span>Personal information</span>
                  </Link>
                </div>
                {/*    <div className="user_home_info pt-2">
                  <Link to="/user_address">
                    <i className="fas fa-house-user me-3"></i>
                    <span>Saved address</span>
                  </Link>
                </div> */}
                <div className="order_info_area mt-4">
                  <p className="border-bottom pb-1 m-1">Order information</p>
                  <i className="fas fa-folder-open me-3"></i>
                  <span>All Order</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-12">
            {
              loading ? <div className="d-flex justify-content-center align-items-center">
                <div className="spinner-border text-danger" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div> : <div className="old_order">
                <div className=" border-bottom pb-1 mb-2">
                  <p className="pb-0 mb-0">Your Order</p>
                </div>
                {data.map((order) =>
                  <div key={order._id} className="single_order_area">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="inner_old_order">
                          <h5>
                            <b>Order:</b> {order.orderId}
                          </h5>
                          <p>
                            <b>Order Date:</b>{" "}
                            {new Date(order?.createdAt).toLocaleDateString(
                              "en-GB"
                            )}
                          </p>
                          <p>
                            <b>Delivered To:</b> {order?.address}
                          </p>
                          <p>
                            <b>Total:</b> {parseInt(order?.cartTotal) + parseInt(order?.deliveryCost)} tk{" "}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="inner_old_order">
                          <button className="processing_btn">
                            {order.status}
                          </button>
                          <p>Possible delivery time Will be notified soon</p>

                          {isCancelabel(order) && <button className="cancel_btn" onClick={() => cancelOrder(order._id)}>Cancel Order</button>}

                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="inner_old_order text-end">
                          <Link to={`/order-details/${order._id}`}>
                            <button className="order_details border">
                              Order Details
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                )}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default OldOrder;
