
import MapSearch from "./MapSearch";
const LoactionModal = () => {
  return (
    <div style={{ width: "180%" }}>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="btn-close text-end m-3"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <h2 className="fw-bold fd-primary-text pt-3 text-center">Choose Your Location</h2>
            <div className="P-1 modal-body">
            <MapSearch></MapSearch>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-fd-primary" data-bs-dismiss="modal">Done</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoactionModal;
