import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import "swiper/css/pagination";
import axiosInstance from "../../Hooks/axios";

/* import "./styles.css"; */


// import Swiper core and required modules
import { Link } from 'react-router-dom';
import SwiperCore, {
    Pagination
} from 'swiper';

// install Swiper modules
SwiperCore.use([Pagination]);
const UnderBanner = () => {

    const [silderData, setSilderData] = useState([]);

    useEffect(() => {
        axiosInstance.get("/banner/homePageBanner?type=2").then((response) => {
            const banner = response.data?.allBanner;
            setSilderData(banner);
        });
    }, []);


    return (
        <div className='my-5'>
            <div className='mx-auto'>
                <Swiper slidesPerView={4} spaceBetween={5} slidesPerGroup={1} loop={true} navigation={true}
                    breakpoints={{
                        240: {
                            slidesPerView: 1,
                            slidesPerGroup: 1,
                        },
                        768: {
                            slidesPerView: 2,
                            slidesPerGroup: 2,
                        },
                        1024: {
                            slidesPerView: 4,
                            slidesPerGroup: 4,
                        },
                    }}
                    className="mySwiper">
                    {silderData.map(data => <SwiperSlide key={data._id}>
                        <Link to={`/resturant/foods/${data?.user?._id}`}>
                            <div className='slider_div'>
                                {/*  <span>{data.remaining}</span>
                            <div className='resturantName'>
                                {data.name}
                            </div> */}
                                <img className='img-fluid' src={`https://api.fooddoose.com/public/uploads/${data?.image}`} alt="" />
                            </div>
                        </Link>
                    </SwiperSlide>)}
                </Swiper>
            </div>
        </div>
    );
};

export default UnderBanner;