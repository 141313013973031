import "./App.css";
import Home from "./Pages/Home/Home/Home";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import About from "./Pages/About/About";
import TC from "./Pages/TC/TC";
import Privacy from "./Pages/Privacy/Privacy";
import ContactUs from "./Pages/ContactUs/ContactUs";
import CancellationAndRefundPolicy from "./Pages/CancellationAndRefundPolicy/CancellationAndRefundPolicy";
// import Faq from "./Pages/Faq/Faq";
// import Apps from "./Pages/Apps/Apps";
// import Resturent from "./Pages/Resturent/Resturent";
// import MarchentLogin from "./Pages/MarchentLogin/MarchentLogin";
// import RiderPage from "./Pages/RiderPage/RiderPage";
// import MerchantSignup from "./Pages/Merchant/MerchantSignup";
import MyCart from "./Pages/MyCart/MyCart";
import Login from "./Pages/UserLoginRegister/Login";
// import CodeFromSMS from "./Pages/UserLoginRegister/CodeFromSMS/CodeFromSMS";
import UserProfile from "./Pages/UserProfile/UserProfile";
// import UserAddress from "./Pages/UserProfile/UserAddress";
import OldOrder from "./Pages/UserProfile/OldOrder";
// import TrackOrder from "./Pages/TrackOrder/TrackOrder";
// import AllFoods from "./Pages/AllFoods/AllFoods";
// import AllResturent from "./Pages/AllResturent/AllResturent";
import NavigationsBar from "./Shared/NavigationsBar/NavigationsBar";
import Footer from "./Shared/NavigationsBar/Footer";
import AuthProvider from "./Context/AuthProvider";
import { CartProvider } from "react-use-cart";
import FrontPage1 from "./Pages/FrontPage/FrontPage1";
// import FrontPageNavigation from "./Shared/FrontPageNavigation/FrontPageNavigation";
import Foods from "./Pages/Foods/Foods/Foods";
// import Register from "./Pages/UserLoginRegister/Register";
import PrivateRoute from "./Hooks/privateRoute";
import OrderDetails from "./Pages/OrderDetails/OrderDetails";
// import { Redirect } from "react-router-dom";

function App() {
  const location = localStorage.getItem("address")


  return (
    <div>
      <AuthProvider>
        <CartProvider>
          <BrowserRouter>
            {location && <NavigationsBar />}
            <Routes>
              <Route path="/"
                element={location ? <Navigate to={
                  "/resturant"} /> : <FrontPage1></FrontPage1>}
              > </Route>
              <Route path="/resturant" element={location ? <Home /> : <Navigate to="/" ></Navigate>} />
                {/* <Route path="/merchant" element={<MarchentLogin />} /> */}
                {/* <Route path="/raider-page" element={<RiderPage />} /> */}
                {/* <Route path="/join-merchant" element={<MerchantSignup />} /> */}
                {/* <Route path="/All-foods" element={<AllFoods />} /> */}
                {/* <Route path="/All-resturrens" element={<AllResturent />} /> */}
              <Route path="/my-cart" element={<PrivateRoute>
                <MyCart />
              </PrivateRoute>} />
              <Route path="/contact" element={<ContactUs />} />
              {/* <Route path="/about" element={<About />} /> */}
              <Route path="/terms" element={<TC />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route
                path="/cancellationAndRefundPolicy"
                element={<CancellationAndRefundPolicy />}
              />
              {/* <Route path="/faq" element={<Faq />} /> */}
              {/* <Route path="/apps" element={<Apps />} /> */}
              <Route path="/login" element={<Login />} />
              {/* <Route path="/register" element={<Register />} /> */}
              {/* <Route path="/code_from_sms" element={<CodeFromSMS />} /> */}
              <Route
                path="/user_profile"
                element={
                  <PrivateRoute>
                    <UserProfile />
                  </PrivateRoute>
                }
              />
              <Route path="/order-details/:id" element={<PrivateRoute><OrderDetails></OrderDetails></PrivateRoute>}>

              </Route>
              {/* <Route path="/user_address" element={<UserAddress />} /> */}
              <Route
                path="/old_order"
                element={
                  <PrivateRoute>
                    <OldOrder />
                  </PrivateRoute>
                }
              />
              {/* <Route path="/track_order" element={<TrackOrder />} /> */}
              <Route path="/resturant/foods/:id" element={<Foods />} />
              <Route path="/*" element={<Navigate to="/" />} />
            </Routes>
            {location && <Footer></Footer>}
          </BrowserRouter>
        </CartProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
