import React from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import logout from "../../images/logout.svg";
import LoactionModal from "../../Pages/Components/LoactionModal";
import useAuth from "../../Hooks/useAuth";

const NavigationsBar = () => {
  const history = useNavigate();
  const { logOut } = useAuth();
  const location = JSON.parse(localStorage.getItem("address"))
  const user = JSON.parse(localStorage.getItem("user"))

  return (
    <div>
      <Navbar fixed="top" bg="light" expand="lg" className="shadow">
        <Container>
          <Navbar.Brand className="mx-auto" to="/">
            {" "}
            <Link to="/resturant">
              <img className="img-fluid" width="150" src={logo} alt="" />
            </Link>{" "}
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto  text-center">
              <Navbar className="fw-bold" to="#home">
                Deliver To
              </Navbar>
              <button
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                className="btn"
              >
                <i
                  style={{ color: "#f37032" }}
                  className="fas fa-map-marker-alt"
                ></i>{" "}
                <span>
                  Current Loaction: <b>{location?.address?.slice(0, 60)} </b>{" "}
                  <small>
                    <i className="fas fa-chevron-down"></i>{" "}
                  </small>{" "}
                </span>{" "}
              </button>
            </Nav>

            { }
            <Nav className="ms-auto">
              {user?.email ? (
                < div className="d-flex align-items-center">
                  <Link to="/user_profile">
                    <span className=""> <img width={40} className="rounded-circle" src={user?.photoURL} alt="" srcSet="" /></span>
                  </Link>
                  {/* <img src={logout} alt="" srcSet="" /> */}

                  <button onClick={() => { logOut(history) }} className="btn ps-3"><img className="" width={30} src={logout} alt="" srcSet="" /></button>
                </div>
              ) : (
                <Link to="/login">
                  <Button className="home-login-btn">Login</Button>
                </Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <LoactionModal></LoactionModal>
    </div>
  );
};

export default NavigationsBar;
