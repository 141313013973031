import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../Hooks/axios";
import useAuth from "../../Hooks/useAuth";
const PopularResturent = () => {
  const [restaurent, setRestaurent] = useState([]);
  const address1 = JSON.parse(localStorage.getItem("address"));
  const { address } = useAuth()
  useEffect(() => {
    axiosInstance.get(`/user/resturent?latitude=${address1.latitude}&longitude=${address1.longitude}&distance=10`).then((response) => {
      const allData = response.data.restaurants;
      setRestaurent(allData);
    });
  }, [address1.latitude, address1.longitude, address]);

  return (
    <div className="container mb-4">
      <div className="d-flex border-top border-bottom py-3 flex-md-row flex-column my-3 justify-content-between align-items-center">
        <span className="food_title text-center">Popular Restaurant</span>
        <div className="food_search">
          <input
            disabled
            type="text"
            placeholder="Search Popular Restaurant"
            name="search-food"
            id=""
          />
        </div>
      </div>

      <div>
        <div className="row gy-4">
          {restaurent.map((data) => (
            <div key={data._id} className="col-12 col-lg-3 col-md-3 col-sm-6">
              <Link className={data.isOpen ? " " : "disable"} to={`foods/${data._id}`}>
                <button disabled={!data.isOpen} className="btn ">
                  <div className="card custom_card h-100 border">
                    <div className=" p-2">
                      <div className="text-center
              ">
                        <img
                          className="img-fluid restaurant-banner"
                          src={`https://api.fooddoose.com/public/uploads/${data.image}`}
                          alt=""
                        />
                      </div>
                      <div className="d-flex align-items-center mx-3 pb-2 mt-2 justify-content-between border-bottom">
                        <div>
                          <img
                            width="40"
                            src={`https://api.fooddoose.com/public/uploads/${data.logo}`}
                            alt=""
                          />
                        </div>
                        <div className="Sfood_title">
                          <h6>
                            <span>{data.shopName}</span>
                          </h6>
                        </div>
                      </div>
                      <div className="d-flex p-3 justify-content-between align-items-end">
                        <div>
                          <small style={{ color: "black" }}>
                            {" "}
                            Total Item: {data.total}
                          </small>
                        </div>
                        <div className="Sfood_title">
                          {data.isOpen ? <span className="badge text-success">Open</span> : <span className="badge text-danger">Close</span>}
                          {/* <span></span> */}
                        </div>
                        <div>
                          <span>
                            <i className="far fa-star text-warning"></i>/5
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PopularResturent;
