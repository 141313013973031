import React from "react";
import play from "../../images/play.png";
import MapSearch from "../Components/MapSearch";
const Banner = () => {
  return (
    <div>
      <div className="location-page">1</div>

      <div className="f-banner-content">
        <a
          target="_blank"
          href="https://play.google.com/store/apps/details?id=com.food.food_dose"
          rel="noreferrer"
        >
          {" "}
          <img className="play-btn w-25 py-2" alt="" src={play}></img>
        </a>

        <div className="p-2">
          <h1 className="text-center bold text-uppercase color-fooddose">
            FoodDoose
          </h1>

          <h5 className="text-center text-case">
            Order Online From Your Favorite Nearby Restaurants
          </h5>
        </div>

        <div className=" ">
          <MapSearch></MapSearch>
        </div>
      </div>

      {/*  <div className="f-banner">
                <div className="f-img-bg">
                    <img className="f-img-1" alt="" src={bannerleft}></img>
                    <img className="f-img-2" alt="" src={bannerright}></img>
                </div>

              

                <div className="f-banner-content">
                    <div className="f-content">
                        <h1 className="f-content-title" style={{ textAlign: "center" }}>
                            Order food online in FoodDoose
                        </h1>
                    </div>

                    <div
                        style={{ width: "60%" }}
                        className="d-flex ms-auto  me-auto  ">
                        <div className="col-md-10 col-12">
                            <MapSearch></MapSearch>
                        </div>

                        <div className="">
                            <button className="w-100 ms-md-2 f-search-btn">Let's go</button>
                        </div>
                    </div>
                </div>
            </div>
 */}
    </div>
  );
};

export default Banner;
