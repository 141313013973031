import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "./useAuth";
export default function PrivateRoute({ children, ...rest }) {
  const location = useLocation();
  const { user, loading } = useAuth()
  if (loading) {
    return <div className="text-center">
      <div
        className="spinner-border text-center text-danger"
        style={{ width: "13rem", height: "13rem" }}
        role="status"
      >
        <span className="sr-only text-danger">Loading...</span>
      </div>
    </div>
  }
  if (!user.email) {
    return <Navigate to="/login" state={{ from: location }} />;
  } return children;
}
