import axios from "axios";
import React, { useEffect, useState } from "react";
import useAuth from '../../../src/Hooks/useAuth';


const MapSearch = () => {
    const { setAddress, address, } = useAuth();
    const [search, setSearch] = useState('');
    const [query, setQuery] = useState('');
    const [show, setShow] = useState(true);
    const [results, setResults] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        query ? setLoading(true) : setLoading(false)
        const delayDebounceFn = setTimeout(() => {
            if (query) {

                axios.get(`https://api.fooddoose.com/3rd-party/auto-complete?q=${query}`)
                    .then(res => {
                        console.log(res.data.data.places);
                        setResults(res.data.data.places)
                        setLoading(false);
                    })
            }
        }, 2000)
        return () => clearTimeout(delayDebounceFn)
    }, [query])


    const handleAdress = () => {
        if (address) {
            localStorage.setItem("address", JSON.stringify(address))
            window.location.reload()
        }

    }
    console.log(isLoading)
    // askAgain()
    return (
        <div className="serach">
            <div className="d-flex">
                <input
                    name="query"
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value)
                        setQuery(e.target.value)
                        setShow(true)
                    }}
                    className="location-search-input" placesautocomplete="true" placeholder="Enter Your Location! Ex: Mirpur 10" type="text" />

                <button onClick={handleAdress} className="f-search-btn">Let's go</button>
            </div>

            {isLoading && <div className="loading pt-3 text-center"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>}
            {show && !isLoading && <div /* style={{ height: "100px", overflowY: "scroll" }} */ className="put scrollbar">

                {
                    results?.map((result) => (
                        <div

                            key={result.id} className="searchResults">
                            <div
                                onClick={() => {
                                    setSearch(result.address);
                                    setAddress(result);
                                    localStorage.setItem("address", JSON.stringify(result))
                                    setShow(!show)

                                }}
                            >
                                {result.address}
                            </div>
                        </div>
                    ))

                }
            </div>}

        </div>
    );
};

export default MapSearch;