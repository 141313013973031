import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from "react-to-print";
import useAxios from '../../Hooks/useAxios';
import logo from '../../images/logoCircle.png';


const OrderDetails = () => {
    const { id } = useParams();
    const [orderData, setOrderData] = useState([]);
    const axiosInstance = useAxios()
    useEffect(() => {
        axiosInstance.get(`/order/single-order/${id}`)
            .then(function (response) {
                setOrderData(response.data.data);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    console.log(orderData);
    // console.log(orderData?.items[0]);
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: id + ".pdf",
        onafterprint: () => alert("Printed"),
    });

    return (
        <div >
            <div className='py-5 mt-5 table-responsive' >
                <div className='d-flex justify-content-evenly pb-2'>
                    <h4 className='bg-danger p-2 text-white rounded'>Order Details</h4>
                    <button className='btn btn-danger' onClick={handlePrint}>Print</button>
                </div>
                <div className="invoice-box" ref={componentRef}>
                    <table cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr className="top">
                                <td colSpan="2">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className="title">
                                                    <img src={logo} style={{ width: "100%", maxWidth: "150px" }} alt="" />
                                                </td>

                                                <td>
                                                    Invoice #: {orderData?._id}<br />
                                                    Created: {new Date(orderData?.createdAt).toLocaleString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })}<br />
                                                    Time: {new Date(orderData?.createdAt).getHours()} : {new Date(orderData?.createdAt).getMinutes()} : {new Date(orderData?.createdAt).getSeconds()}<br />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>

                        <tbody>
                            <tr className="information">
                                <td colSpan="2">
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>
                                                    {orderData?.merchant?.shopName}<br />
                                                    {orderData?.merchant?.address ? orderData?.merchant?.address : "Unknown Location"}<br />
                                                    {orderData?.merchant?.number}
                                                </td>

                                                <td>
                                                    {orderData?.name}<br />
                                                    {orderData?.address}<br />
                                                    {orderData?.phone ? orderData?.phone : "Unknown Number"}
                                                </td>
                                            </tr>
                                        </thead>
                                    </table>
                                </td>
                            </tr>
                        </tbody>


                    </table>
                    <div className='table-responsive'>
                        <table className="table text-center ">
                            <thead className='thead-light'>
                                <tr>
                                    <th scope="col">Item Name</th>
                                    <th scope="col">Previous Price</th>
                                    <th className='text-danger' scope="col">Discount</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Food Price </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    orderData?.items?.map((item) => (
                                        <tr>
                                            <th className='text-left' scope="row">{item?.itemName}</th>
                                            <td className='text-center'>{item?.withOutDiscountPrice}</td>
                                            <td className='text-danger'>{item?.discountPrice * item?.quantity}</td>
                                            <td>{item?.quantity}</td>
                                            <td>{item?.price}</td>
                                            <td className='text-end'>{item?.price * item?.quantity}</td>
                                        </tr>
                                    ))
                                }

                                <tr className='thead-light'>
                                    <td></td>
                                    <td>Delivery Cost</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className='text-end'>{orderData?.deliveryCost} </td>
                                </tr>

                                <tr className='thead-light'>
                                    <td></td>
                                    <td className='text-danger'>Coupon Discount</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className='text-end text-danger'> -{orderData?.discount} </td>
                                </tr>
                                <tr className='bg-primary text-light fw-bold'>
                                    <td></td>
                                    <td>Total: </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td className='text-end'>{parseInt(orderData?.cartTotal) + parseInt(orderData?.deliveryCost)} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default OrderDetails;