
const DeliveryOptions = ({ setMark, mark, deliveryCost, setCost }) => {
    const changehandler = (props, value) => {
        setMark(props)
        setCost(value)
    }
    const location = JSON.parse(localStorage.getItem("address"))
    console.log(deliveryCost)
    return (
        <div>
            <div className='pb-5 mx-3'>
                <h5 className='fw-bold'>
                    Delivery Optinos
                </h5>
                <button style={{ backgroundColor: 'rgba(255,255,255,0)' }} className="form-check">
                    <input className="form-check-input" onClick={() => { changehandler(true, deliveryCost?.cost) }} type="radio" name="exampleRadios" id="exampleRadios1" defaultChecked />
                    <label className="form-check-label" htmlhtmlfor="exampleRadios1">Today Delivery (<span className='fw-bold'>{deliveryCost?.cost} ৳</span>)
                    </label>
                </button>
                <button style={{ backgroundColor: 'rgba(255,255,255,0)' }} className="form-check">
                    <input onClick={() => { changehandler(false, 0) }} className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" />
                    <label className="form-check-label" htmlhtmlfor="exampleRadios2">
                        Take Away (<span className='fw-bold'>Free</span>)
                    </label>
                </button>
                {
                    mark && <div>
                        <div className='d-flex justify-content-between pt-3'>
                            <h5 className='fw-bold'>
                                Deliver To
                            </h5>
                            <button style={{ backgroundColor: 'rgba(255,255,255,0)' }} className=' fw-bold text-warning' data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <i className="fas fa-plus"></i> Add
                            </button>
                        </div>

                        <h1 className='border p-3'>    <i className="fas text-warning fa-map-marker-alt"></i> Location: <span className='fs-3 text-warning'>{location?.address.slice(0, 60)}</span> </h1>
                    </div>
                }
            </div>

        </div>
    );
};

export default DeliveryOptions;