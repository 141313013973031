
import axios from "axios";
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import initializeAuthentication from "../Firebase/firebase.init";


initializeAuthentication();
const useFunctions = () => {
  const [deliveryCharge, setDeliveryCharge] = useState(0);

  const [getLocation, setGetLocation] = useState("");
  const [address, setAddress] = useState("");
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true)
  const auth = getAuth();
  const [menu, setMenu] = useState([]);
  const googleProvider = new GoogleAuthProvider();
  let userInfo;
  const signInUsingGoogle = async (location, history) => {
    setLoading(true);
    try {

      signInWithPopup(auth, googleProvider).then((result) => {
        userInfo = result.user;
        axios.post("https://api.fooddoose.com/simple-user/login", { userInfo }).then((response) => {
          try {
            if (response.status === 200) {
              console.log("response", response);
              localStorage.setItem("accessToken", response.data.accessToken);
              localStorage.setItem("refreshToken", response.data.refreshtoken);
              localStorage.setItem("user", JSON.stringify(response.data.userData));
              setUser(response.data.userData);
            }

          }
          catch (error) {
            logOut(history)
          }
        })
        const destination = location?.state?.from ? location?.state?.from : "/resturant";
        history(destination);
      });
    } finally {
      setLoading(false);
    }
  }
  const logOut = (history) => {
    setLoading(true);
    signOut(auth)
      .then(() => {
        setUser({})
        localStorage.removeItem("user")
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");

      })
      .finally(() => {
        setLoading(false);
        history("/login")
      })
  }

  // observe whether user auth state changed or not
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      }
      else {
        setUser({});
      }
      setLoading(false);
    });
    return () => unsubscribe;
  }, [auth])
  const userAddress = JSON.parse(localStorage.getItem("address"))
  const calculateDeliveryCharge = (lat2, lon2) => {

    axios.get(`https://api.fooddoose.com/delivery-cost/get-delivery-cost?lat1=${userAddress.longitude}&lon1=${userAddress.latitude}&lat2=${lat2}&lon2=${lon2}`).then((response) => {
      setDeliveryCharge(response.data)
    })
    return deliveryCharge;

  }
  return {
    calculateDeliveryCharge,
    setDeliveryCharge,
    deliveryCharge,
    setAddress,
    address,
    userInfo,
    getLocation,
    setGetLocation,
    signInUsingGoogle, user, logOut, loading,
    menu, setMenu

  };
};
export default useFunctions;



