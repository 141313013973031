import React, { useEffect, useState } from "react";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import axiosInstance from "../../Hooks/axios";

const Slider = ({ type, id }) => {
  const [silderData, setSilderData] = useState([]);
  const url = id ? `/banner/homePageBanner?type=${type}&id=${id}` : `/banner/homePageBanner?type=${type}`;
  useEffect(() => {
    axiosInstance.get(url).then((response) => {
      const banner = response.data?.allBanner;
      setSilderData(banner);
    });
  }, [url]);

  return (
    <div>
      <>
        <Swiper
          // cssMode={true}
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper banner"
        >
          {silderData.map((data) => (
            <SwiperSlide key={data.image}>
              <div className="banner container">
                <img
                  className="banner-image"
                  src={`https://api.fooddoose.com/public/uploads/${data?.image}`}
                  alt=""
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </>
    </div>
  );
};

export default Slider;
