import React, { useEffect } from "react";
import { useCart } from "react-use-cart";
const PopularFood = ({ products, setDisplayProducts, setIsSameRestaurent, displayProducts, loading, isSameRestaurent }) => {

  const { addItem, emptyCart, items } = useCart();
  const handleSearch = (event) => {
    const searchText = event.target.value;
    const matchedProducts = products.filter((product) =>
      product.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setDisplayProducts(matchedProducts);
  };
  const handleEmptyCart = () => {
    emptyCart();
    setIsSameRestaurent(true);
  };


  console.log(items)

  useEffect(() => {
    const merchantAddress = JSON.parse(localStorage.getItem("merchantAddress"));
    if (!merchantAddress && items.length > 0) {
      localStorage.setItem("merchantAddress", JSON.stringify(items[0]?.user.location));
    }
    if (items.length <= 0 && merchantAddress) {
      localStorage.removeItem("merchantAddress");
    }
  }, [items]);

  return (
    <div className="container populer">
      <div className="d-flex border-top border-bottom py-3 flex-md-row flex-column my-3 justify-content-between align-items-center">
        <span className="food_title">{displayProducts[0]?.shopName}</span>
        <div className="food_search">
          <input
            onChange={handleSearch}
            type="text"
            placeholder="Search Food"
            name="search-food"
            id="search-food"
          />
        </div>
      </div>
      {loading ? <div className="text-center p-md-5 p-lg-5">
        <div className="spinner-border text-primary text-center" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div> :
        <div className="row gy-4">
          {displayProducts.length === 0 && (
            <h5 className="text-center text-danger py-5">No Food Found......</h5>
          )}
          {displayProducts.map((data) => (
            <div key={data._id} className="col-12 col-lg-4 col-md-4 col-sm-6 mb-5 pb-5">
              <div className="card_overlay">
                <div className="custom_card p-2">
                  <div className="food_image">
                    <img
                      className="w-100"
                      src={`https://api.fooddoose.com/public/uploads/${data.image}`}
                      alt=""
                    />
                  </div>
                  <div className="d-flex align-items-center mx-3 pb-2 mt-2 justify-content-between border-bottom">
                    <div>
                      <img
                        width="40"
                        src={`https://api.fooddoose.com/public/uploads/${data.logo}`}
                        alt=""
                      />
                    </div>
                    <div className="Sfood_title">
                      <h6>
                        <span>{data.itemName}</span>
                      </h6>
                      <p>{data?.shortDescription}</p>
                    </div>
                  </div>
                  <div className="d-flex p-3 justify-content-between">
                    <div>
                      <small> Tk. {data.price}</small>
                    </div>
                    <div className="Sfood_title">
                      <span>
                        {data.deliveryTime}
                        {data.timeFormat}m
                      </span>
                    </div>
                    <div>
                      <span>
                        <i className="far fa-star text-warning"></i>/5
                      </span>
                    </div>
                  </div>
                </div>
                <div className="add_to_cart">
                  {isSameRestaurent ? (
                    <button
                      disabled={!data.avilableForOrder}
                      onClick={() =>
                        addItem({ ...data, id: data._id, name: data.itemName })
                      }
                      className="btn text-light"
                      style={{ backgroundColor: "rgb(244, 111, 34)" }}
                    >
                      {" "}
                      Add to Cart
                    </button>
                  ) : (
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal2"
                      className="btn text-light"
                      style={{ backgroundColor: "rgb(244, 111, 34)" }}
                    >
                      {" "}
                      Add to Cart
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>}

      {/* modal */}
      <div style={{ width: "180%" }}>
        <div
          className="modal fade"
          id="exampleModal2"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn-close text-end m-3"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                <h2 className="fw-bold fd-primary-text pt-3 pe-5">
                  Please empty cart
                </h2>
              </div>

              <div
                className="P-1 p-md-5 modal-body"
                style={{ height: "70vh" }}
              ></div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleEmptyCart}
                >
                  Empty
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal */}
    </div>
  );
};

export default PopularFood;
