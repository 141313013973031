import React, { createContext } from 'react';
import useFunctions from '../Hooks/useFunctions'

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    // const { children } = props;
    const allContexts = useFunctions();
    return (
        <AuthContext.Provider value={allContexts}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;