import React from 'react';
import header from "../../images/header_Image.16d8d24.png";

const Privacy = () => {
  return (
    <div style={{ paddingTop: '50px' }}>
      <div className="container main-container" data-v-47654002=""><div className="image-container"><img src={header} alt="imge" /> <div className="text"><h1 className="head-text">
        PRIVACY POLICY
      </h1> <p className="secondary-text">
          Company Pilicy Can Be Found Here
        </p></div></div></div>


      <div className="container" data-v-47654002=""><div className="body" data-v-47654002=""><p data-v-47654002="">This is our privacy policy and it explains how we, FoodDoose, use the personal information which has been provided by you when using our service, including but not limited to our website and mobile applications (apps).</p> <section data-v-47654002=""><div className="spacer" data-v-47654002=""></div> <p className="bold" data-v-47654002="">
        Section 1: What information do we collect about you?
      </p> <p data-v-47654002="">
          When you order from us or use our website, we collect personal information from you that you provide us when using our service. We also collect information when you complete any customer survey. We may also receive information about your website usage which is collected using cookies.
        </p></section><section data-v-47654002=""><div className="spacer" data-v-47654002=""></div> <p className="bold" data-v-47654002="">
          Section 2: How will we use the information we collect from you?
        </p> <p data-v-47654002="">
            Your personal information is sent to the delivery team for delivery. We also use your email id to send you newsletters or other information you have subscribed to. Time and again we have lucky draws among our customers for which we use their email IDs. Restaurant or FoodDoose will never use your personal information for any other purpose other than the ones mentioned above. We take security seriously and we take precautions to keep your personal information secure. We have put in place appropriate physical, electronic and managerial procedures to safeguard the information we collect. However, due to the open communication nature of the Internet, FoodDoose cannot always guarantee that communications between you and FoodDoose or information stored on FoodDoose servers will be 100% safe. However, they will be free from unauthorized access by third parties.
          </p></section><section data-v-47654002=""><div className="spacer" data-v-47654002=""></div> <p className="bold" data-v-47654002="">
            Section 3: Other Websites
          </p> <p data-v-47654002="">
            Our privacy policy only applies to this website, but our website may have links to other websites. Therefore, when you are using other websites, please read the privacy policies of those websites.
          </p></section></div></div>
    </div>
  );
};

export default Privacy;