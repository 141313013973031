import React from "react";
import Banner from "../Components/Banner";
import "./FrontPage.css";
const FrontPage1 = () => {
  return (
    <div>
      <Banner></Banner>
    </div>
  );
};

export default FrontPage1;
