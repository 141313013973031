import React, { useRef, useEffect } from "react";
import "./Login.css";
import LoginImg from "../../images/User_Login/login1.png";
import icon from "../../images/google.png";
import { useNavigate, useLocation } from "react-router-dom";


import useAuth from "../../Hooks/useAuth";

const Login = () => {


  const { signInUsingGoogle, user, loading } = useAuth();
  const form = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();


  const handleGoogleSignIn = () => {
    signInUsingGoogle(location, navigate)
  }
  const rediract = location.state?.from || "/";

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    user?.email ? navigate(rediract) : ""
  }, [user, navigate, rediract]);

  return (
    <>
      <div className="login_area" id="login_area">
        <div className="container">
          <div className="row border py-3 my-3 d-flex justify-content-evenly align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="login_inner_left_site">
                <img className="w-75" src={LoginImg} alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-5 col-sm-12">
              <div className="login_inner_right_site">
                <div className="login_form">
                  <h2>Log in</h2>
                  {loading ? (
                    <div className="text-center">
                      <div
                        className="spinner-border text-center text-danger"
                        style={{ width: "13rem", height: "13rem" }}
                        role="status"
                      >
                        <span className="sr-only text-danger">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <div
                      ref={form}
                      // onSubmit={submit}
                      className="add-merchant"
                    // encType="multipart/form-data"
                    // multiple
                    >
                      <div className="row">
                        {/* <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                          <label htmlhtmlfor="name">Email</label>
                          <input
                            className="form-control"
                            type="email"
                            name="email"
                            id="email"
                            defaultValue={data?.email}
                            placeholder="Email"
                          />
                          <div style={{ color: "red" }}>
                            {errors?.email && errors?.email.msg}
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                          <label htmlhtmlfor="password">Password</label>
                          <input
                            className="form-control"
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Pasword"
                            defaultValue={data?.password}
                          />
                          <div style={{ color: "red" }}>
                            {errors?.password && errors?.password.msg}
                          </div>
                        </div>
                        <button
                          style={{ margin: "10px" }}
                          className="btn btn-success mt-2 common-color"
                        >
                          Login
                        </button> */}
                        <button className="btn" onClick={handleGoogleSignIn}>
                          <div className="d-flex justify-content-evenly align-items-center">
                            <div>
                              <img width={40} height="40" src={icon} alt="" />
                            </div>
                            <div>
                              <h5 className="m-0">Continue With Google</h5>
                            </div>
                          </div>
                        </button>
                        {/*  <div>
                          <p>
                            Not registered?
                            <Link style={{ color: "blue" }} to="/register">
                              Register now
                            </Link>
                          </p>
                        </div> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
