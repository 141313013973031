import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCart } from "react-use-cart";
import axiosInstance from '../../../Hooks/axios';
import Cart from '../../Components/Cart';
import PopularFood from '../../Components/PopularFood';
import ResturentMenu from '../../Components/ResturentMenu';
import Slider from '../../Components/Slider';
// import axiosInstance from "../../../"

const Foods = () => {
    const { id } = useParams();
    const [menu, setMenu] = useState([])
    const [categoryName, setCategoryName] = useState("all")
    const { items, isEmpty } = useCart();
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [displayProducts, setDisplayProducts] = useState([]);
    const [isSameRestaurent, setIsSameRestaurent] = useState(true);

    useEffect(() => {
        axios.get(`https://api.fooddoose.com/category/ui/${id}`)
            .then((response) => {
                setMenu(response.data.categories);
            })
    }, [id]);


    useEffect(() => {

        if (isEmpty) {
            setIsSameRestaurent(true);
        } else if (items[0]?.user._id !== id) {
            setIsSameRestaurent(false);
        }
        setLoading(true);
        axiosInstance.get(`food/all/${id}?categoryName=${categoryName}`).then((response) => {
            setProducts(response?.data.allFoods);
            setDisplayProducts(response?.data.allFoods);
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryName, id]);

    return (
        <div style={{ paddingTop: '85px' }}>
            {<Cart></Cart>}
            <div className="container">
                <Slider type="2" id={id}></Slider>
                <div className="row">
                    <div className="col-md-3">
                        <div className='pt-5'>
                            <ResturentMenu menu={menu} setCategoryName={setCategoryName}></ResturentMenu>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <PopularFood products={products} setDisplayProducts={setDisplayProducts} loading={loading} displayProducts={displayProducts} setIsSameRestaurent={setIsSameRestaurent} isSameRestaurent={isSameRestaurent}></PopularFood>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Foods;