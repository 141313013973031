import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCart } from "react-use-cart";
import useAuth from "../../Hooks/useAuth";
const Cart = () => {
  const userAddress = JSON.parse(localStorage.getItem("address"))
  const [deliveryCost, setDeliveryCost] = useState({})

  const {
    items,
    isEmpty,
    totalUniqueItems,
    totalItems,
    removeItem,
    cartTotal,
    updateItemQuantity
  } = useCart();
  const { address } = useAuth()
  useEffect(() => {
    if (items.length > 0) {
      axios.get(`https://api.fooddoose.com/delivery-cost/get-delivery-cost?lat1=${userAddress.longitude}&lon1=${userAddress.latitude}&lat2=${items[0]?.user.location.coordinates[0]}&lon2=${items[0]?.user.location.coordinates[1]}`).then((response) => {
        setDeliveryCost(response.data)
      })

    }

  }, [userAddress.longitude, address, items, userAddress.latitude])

  // items[0]?.user.location.coordinates[1], userAddress.longitude, address.id
  return (
    <div>
      <div className="myCart shadow-lg d-flex flex-column justify-content-center align-items-center">
        <button
          className="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
        >
          <div>
            <div
              className="d-flex flex-column justify-content-center align-items-center p-2"
              style={{
                backgroundColor: "rgb(85, 88, 77)",
                color: "rgb(253, 214, 112)",
              }}
            >
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="0"
                viewBox="0 0 24 24"
                className="fs-4"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                ></path>
              </svg>
              <p className="m-0">
                <span className="pe-1">{totalUniqueItems}</span>
                <span>ITEMS</span>
              </p>
            </div>
            <p
              className="m-0 px-2 wrap"
              style={{ backgroundColor: "rgb(248, 248, 248)" }}
            >
              <span>৳</span> {cartTotal}
            </p>
          </div>
        </button>
      </div>
      <div
        style={{ marginTop: "79px" }}
        className="offcanvas offcanvas-end mycanvas" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          {isEmpty ? <h5 id="offcanvasRightLabel" >Your Cart is Empty</h5> : <h5 id="offcanvasRightLabel" >Total Item ({totalItems})</h5>}

          <button

            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <div className="true">
          <div className="offcanvas-body">
            {isEmpty ? <h2 className="text-center">
              Please Add Food
            </h2> : <div className="container custom_cart_page mx-auto  show">
              <div className="d-flex align-items-center">
                <img
                  src="https://icon2.cleanpng.com/20180331/ezq/kisspng-catering-food-computer-icons-logo-event-management-catering-5abf487c5eb658.714031151522485372388.jpg"
                  className="img-fuild"
                  alt=""
                  style={{ width: "1.5rem" }}
                />
                <div className="d-flex flex-column ms-3">
                  <span>Ordered From</span>
                  <span style={{ color: "rgb(244, 111, 34)" }}>Chunk - </span>
                </div>
              </div>
              <div className="card p-3 px-2 mt-4">
                {
                  items.map(food => <div key={food.id}
                    className="container position-relative py-3"
                  >
                    <div
                      className="row"
                      style={{ borderBottom: "1px solid rgb(226, 197, 197)" }}
                    >
                      <div className="col-md-6">
                        <h6 style={{ fontWeight: "bolder" }}>{food.name}</h6>
                        {/*  <span style={{ fontSize: "0.7rem", color: "gray" }}>
                          Toppings: No Toppings
                        </span> */}
                        <span> Tk. {food.price}</span>


                      </div>
                      <div className="d-flex justify-content-between align-items-center col-md-6 ">
                        <div className="d-flex align-items-center">
                          <button
                            onClick={() => { updateItemQuantity(food.id, food.quantity - 1) }}
                            className="btn text-warning"><i className="fas fa-minus"></i>
                          </button>
                          {food.quantity}
                          <button
                            onClick={() => { updateItemQuantity(food.id, food.quantity + 1) }}
                            className="btn text-warning"><i className="fas fa-plus"></i></button>
                        </div>
                        <br />
                        <div>

                          <span>{(food.price * food.quantity) && (food.price * food.quantity)}৳</span>
                        </div>
                      </div>
                    </div>
                    <button onClick={() => { removeItem(food.id) }} type="button" className="btn-close btn-sm cross"></button>
                  </div>)
                }

                <div
                  className="py-3"
                  style={{ borderBottom: "1px solid rgb(226, 197, 197)" }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <span style={{ fontSize: "0.9rem", color: "gray" }}>
                      Food Price
                    </span>
                    <span style={{ fontSize: "0.9rem", color: "gray" }}>
                      {cartTotal}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <span style={{ fontSize: "0.9rem", color: "gray" }}>
                      Delivery Fee
                    </span>
                    <span style={{ fontSize: "0.9rem", color: "gray" }}>


                      Tk. {deliveryCost.cost}
                    </span>
                  </div>
                </div>
                <div className="pt-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <span style={{ fontSize: "1.1rem", color: "black" }}>
                      Total
                    </span>
                    <div className="d-flex flex-column">
                      <span style={{ fontSize: "1.1rem", color: "orangered" }}>
                        {cartTotal !== isNaN}
                        {(deliveryCost.cost !== isNaN) && cartTotal + deliveryCost.cost}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn  rider-tip pointer mt-3 py-3 text-white " style={{ backgroundColor: 'rgb(244, 111, 34)', width: '100%' }}><Link to="/my-cart">Place Order. Pay Tk. {cartTotal + deliveryCost.cost}</Link> </div>

            </div>}
          </div>
        </div>
      </div>
    </div >
  );
};

export default Cart;
