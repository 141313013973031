const ResturentMenu = ({ menu, setCategoryName }) => {
    const clickHandler = (e) => {
        setCategoryName(e)
    }
    return (
        <div>
            <ul className='nav d-flex flex-column py-3'>
                <div>
                    <h2>Filter by Category</h2>
                </div>
                <li className='active'><button className='btn ' onClick={() => { clickHandler("all") }}>All Food</button></li>

                {menu?.map((data) => <li key={data?._id}>
                 
                    <button className='btn' onClick={() => { clickHandler(data.categoryName) }}>{data.categoryName}</button>
                </li>)}



            </ul>

        </div>
    );
};

export default ResturentMenu;