import React from 'react';
import './Contact.css'
import header from "../../images/header_Image.16d8d24.png";

const ContactUs = () => {
  return (
    <div style={{ paddingTop: '60px' }}>
      <div className="container main-container" data-v-419d9ece=""><div className="image-container">
        <img src={header} alt="imag" />
        <div className="text"><h1 className="head-text">
          CONTACT US
        </h1> <p className="secondary-text">
            Hello, is it me you’re looking for?
          </p>
        </div>
      </div>
      </div>

      <div className="container main" data-v-419d9ece="">
        <p data-v-419d9ece="">Please carefully read the terms and conditions set out below before ordering any food or services from this website. Because if you do so you agree to be bound by these terms and conditions.</p>
        <div className="address" data-v-419d9ece=""><p className="head-text" data-v-419d9ece="">
          Head Office
        </p> <p data-v-419d9ece="">Food Doose</p> <p data-v-419d9ece="">Bhawer-vity abdullahpur south keraniganj Dhaka, 1311</p> <p data-v-419d9ece="">Dhaka, Bangladesh.</p> <p data-v-419d9ece="">Contact: +880 18124-200755</p> <p data-v-419d9ece="">email: fooddoose@gmail.com</p></div>
        <div className="map-container" data-v-419d9ece=""><p className="head-text" data-v-419d9ece="">
          Our Location
        </p>


          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3654.470106024032!2d90.35019065070894!3d23.659140684559006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755bdbf01dfd037%3A0x28cbfac1276f545e!2sFood%20Doose!5e0!3m2!1sen!2sbd!4v1666297637098!5m2!1sen!2sbd"
            title='location ' width="100%" height="400" frameborder="0" style={{ border: 0 }} allowfullscreen></iframe>

        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default ContactUs;