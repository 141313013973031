import axios from "axios";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import useAuth from "./useAuth";

const baseURL = "https://api.fooddoose.com";
const useAxios = () => {
  const { logOut } = useAuth();
  const history = useNavigate();
  let accessToken = localStorage.getItem("accessToken");
  let refreshToken = localStorage.getItem("refreshToken");
  const axiosInstance = axios.create({
    baseURL,
    headers: { Authorization: accessToken },
  });
  axiosInstance.interceptors.request.use(async (request) => {
    let Token = "";
    if (!accessToken) {
      accessToken = localStorage.getItem("accessToken")
        ? localStorage.getItem("accessToken")
        : null;
      request.headers.Authorization = accessToken;
    }
    const { exp } = jwt_decode(accessToken);
    const isExpried = exp < (new Date().getTime() ) / 1000;
    
    console.log(exp, (new Date().getTime() + 1) / 1000, isExpried);
    if (!isExpried) return request;
    await axios
      .get(`${baseURL}/simple-user/refreshToken?token=${refreshToken}`)
      .then((res) => {
        if (res.status === 200) {
          Token = res.data.accesstoken;
          localStorage.setItem("accessToken", Token);
        }
      })
      .catch((error) => {
        console.log(error);
        logOut(history);
        history("/login");
      });
    request.headers.Authorization = Token;
    return request;
  });
  return axiosInstance;
};
export default useAxios;
