import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCart } from "react-use-cart";
import Swal from "sweetalert2";
import useAuth from "../../Hooks/useAuth";
import useAxios from "../../Hooks/useAxios";
import DeliveryOptions from "../Components/DeliveryOptions";

const MyCart = () => {
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeData, setCouponCodeData] = useState(0);
  const [mark, setMark] = useState(true)
  const [loading, setLoading] = useState(false);
  const { address } = useAuth()
  const history = useNavigate();
  const axiosInstance = useAxios()
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
  const [deliveryCost, setDeliveryCost] = useState({});
  const [cost, setCost] = useState(deliveryCost?.cost)
  const location = JSON.parse(localStorage.getItem("address"))
  const {
    items,
    isEmpty,
    totalUniqueItems,
    totalItems,
    removeItem,
    cartTotal,
    updateItemQuantity,
    emptyCart,
  } = useCart();

  const handleOrder = async (e) => {
    e.preventDefault();


    if (/^(01[3456789])(\d{8})$/.test(phone)) {
      setLoading(true);
      axiosInstance.post(
        "/order/order-foods",
        {
          phone,
          message,
          items,
          address: mark ? location.address : "Self Pickup",
          totalItems,
          totalUniqueItems,
          deliveryCost: cost,
          discount: couponCodeData.discount ? couponCodeData.discount : 0,
          cartTotal: couponCodeData.discount ? cartTotal - couponCodeData.discount : cartTotal,
          couponID: couponCodeData._id ? couponCodeData._id : null,
        },

      )
        .then(function (response) {
          if (response.status === 200) {
            Toast.fire({
              icon: "success",
              title: "Order successfull!!",
            });
            emptyCart();
            history("/old_order");
            setLoading(false);
          } else if (response.status === 400) {
            Toast.fire({
              icon: "error",
              title: response.data.msg,
            });
            setLoading(false);
          }
          // setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };
  const userAddress = JSON.parse(localStorage.getItem("address"))
  useEffect(() => {
    if (items.length > 0) {
      axios.get(`https://api.fooddoose.com/delivery-cost/get-delivery-cost?lat1=${userAddress.longitude}&lon1=${userAddress.latitude}&lat2=${items[0]?.user.location.coordinates[0]}&lon2=${items[0]?.user.location.coordinates[1]}`).then((response) => {
        setDeliveryCost(response.data)
        setCost(response.data.cost)
      })
    }

  }, [userAddress.longitude, address, items, userAddress.latitude])

  const matchCouponCode = () => {
    axiosInstance.post("/couponCode/match-coupon-code", { couponCode, price: cartTotal, merchantId: items[0]?.user._id }).then((response) => {
      console.log(response.response);
      if (response.status === 200) {
        Toast.fire({
          icon: "success",
          title: "Coupon Code Matched",
        });
        setCouponCodeData(response.data)
        setCouponCode("")
      } else if (response.status === 400) {
        Toast.fire({
          icon: "error",
          title: response.data.msg,
        });
      }
    })
      .catch((error) => {
        console.log(error.response);
        Toast.fire({
          icon: "error",
          title: error.response.data.msg,
        });
      })
  }

  return (
    <div className="mt-5 py-5">
      <div className="container custom_cart_page mx-auto">

        {/* <PrepranceTime></PrepranceTime> */}
        {
          !isEmpty && <DeliveryOptions
            setCost={setCost}
            cost={cost}
            deliveryCost={deliveryCost} setDeliveryCost={setDeliveryCost}
            setMark={setMark}
            mark={mark}
          ></DeliveryOptions>
        }
        {isEmpty ? (
          <div className="py-5">
            <h1 className="text-center">Your Cart is Empty</h1>
            <h2 className="text-center bg-warning py-2">
              <Link to="/">Go Home and order more</Link>
            </h2>
          </div>
        ) : (
          <div className="container custom_cart_page mx-auto  show">
            <div className="d-flex align-items-center">

              <img
                src={`https://api.fooddoose.com/public/uploads/${items[0].user.logo}`}
                className="img-fuild"
                alt=""
                style={{ width: "1.5rem" }}
              />
              <div className="d-flex flex-column ms-3">
                <span>Ordered From</span><span style={{ color: "rgb(244, 111, 34)" }}>{items[0].user.shopName} </span>
              </div>
            </div>
            <div className="card p-3 px-2 mt-4">
              {items.map((food) => (
                <div key={food._id} className="container position-relative py-3">
                  <div
                    className="row"
                    style={{ borderBottom: "1px solid rgb(226, 197, 197)" }}
                  >
                    <div className="col-md-6">
                      <h6 style={{ fontWeight: "bolder" }}>{food.name}</h6>
                      <span> Tk. {food.price}</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center col-md-6 ">
                      <div className="d-flex align-items-center">
                        <button
                          onClick={() => {
                            updateItemQuantity(food.id, food.quantity - 1);
                          }}
                          className="btn text-warning"
                        >
                          <i className="fas fa-minus"></i>
                        </button>
                        {food.quantity}
                        <button
                          onClick={() => {
                            updateItemQuantity(food.id, food.quantity + 1);
                          }}
                          className="btn text-warning"
                        >
                          <i className="fas fa-plus"></i>
                        </button>
                      </div>

                      <br />
                      <div>
                        <span>{(food.price * food.quantity)}৳</span>
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      removeItem(food.id);
                    }}
                    type="button"
                    className="btn-close btn-sm cross"
                  ></button>
                </div>
              ))}
              <div
                className="py-3"
                style={{ borderBottom: "1px solid rgb(226, 197, 197)" }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <span style={{ fontSize: "0.9rem", color: "gray" }}>
                    Food Price
                  </span>
                  <span style={{ fontSize: "0.9rem", color: "gray" }}>
                    {cartTotal}
                  </span>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <span style={{ fontSize: "0.9rem", color: "gray" }}>
                    Delivery Fee
                  </span>
                  <span style={{ fontSize: "0.9rem", color: "gray" }}>
                    Tk. {cost}
                  </span>
                </div>
                {
                  couponCodeData.discount && <div className="d-flex justify-content-between align-items-center">
                    <span style={{ fontSize: "0.9rem", color: "green" }}>
                      Discount
                    </span>
                    <span style={{ fontSize: "0.9rem", color: "green" }}>
                      Tk. - {couponCodeData.discount}
                    </span>
                  </div>
                }
              </div>
              <div className="pt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <span style={{ fontSize: "1.1rem", color: "black" }}>
                    Total
                  </span>
                  <div className="d-flex flex-column">
                    <span style={{ fontSize: "1.1rem", color: "orangered" }}>
                      {cartTotal !== isNaN}
                      {cartTotal + cost - (couponCodeData.discount ? couponCodeData.discount : 0)}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <div className="row">

                <div className="col-md-6 col-sm-12 col-12">
                  <input
                    onChange={(e) => { setPhone(e.target.value) }}
                    placeholder="Enter a valid Phone number" className="m-2 p-2 w-100" required={true} type="tel" name="phone" id="phone" />
                </div>
                <div style={{ position: "relative" }} className="col-md-6 col-sm-12 col-12">
                  <input
                    onChange={(e) => { setCouponCode(e.target.value) }}
                    value={couponCode}
                    placeholder="Enter Coupon Code" className="m-2 p-2 w-100" type="text" name="couponCode" id="couponCode" />
                  <button
                    onClick={matchCouponCode}
                    disabled={couponCodeData.discount ? true : false}
                    className="" style={{ position: "absolute", top: 15, right: 15, backgroundColor: couponCodeData.discount ? "GrayText" : "rgb(244, 111, 34)", color: "white", padding: "3px 10px", borderRadius: "10%" }}>Apply</button>
                </div>
              </div>
              <br />
              <textarea
                onChange={(e) => { setMessage(e.target.value) }}
                className="m-2 p-2" name="message" placeholder="Any Comments" id="" style={{ width: "100%" }}></textarea>
              {
                deliveryCost.distance > 10 ? <small className="text-danger">Sorry! Your distance more then 10km. so, you can not order from this Resturent</small> : <></>
              }

              <button
                {...((loading || deliveryCost.distance > 10) ? { disabled: true } : {})}
                // onSubmit={handleOrder}
                type="submit"
                onClick={handleOrder}
                className="btn    btn-disabled rider-tip pointer mt-3 py-3 text-white "
                style={{ backgroundColor: "rgb(244, 111, 34)", width: "100%" }}
              >
                Place Order. Pay Tk. {cartTotal + cost - (couponCodeData.discount ? couponCodeData.discount : 0)}
              </button>

            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyCart;